/* ==========================================================================
   TABLES
   ========================================================================== */

table {
  width: 100%;
  font-family: $global-font-family;
  font-size: $type-size-6;
  border-collapse: collapse;
  overflow-x: auto;
  display: inline;
  & + table {
    margin-top: 1em;
  }
}

.table-wrapper {
  margin-right: auto;
  margin-left: auto;
//  text-align: center;
  margin-bottom: 1em;
}

thead {
  background-color: $border-color;
  border-bottom: 2px solid mix(#000, $border-color, 25%);
}

th {
  padding: 0.5em;
  font-weight: bold;
  text-align: left;
}

td {
  padding: 0.5em;
  border-bottom: 1px solid mix(#000, $border-color, 25%);
}

tr,
td,
th {
  vertical-align: middle;
}